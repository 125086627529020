import Splide from '@splidejs/splide';
import URLHash from '@splidejs/splide-extension-url-hash';
const bootstrap = require('bootstrap');

function heroSlider () {
  let splide;
  let previousButton, nextButton;

  document.addEventListener('DOMContentLoaded', function() {
    previousButton = document.querySelector('.block-hero-slider__section .previous-button');
    nextButton = document.querySelector('.block-hero-slider__section .next-button');

    splide = new Splide('.splide', {
      autoplay: false,
      interval: 7000,
      pauseOnHover: false,
      arrows: false,
      perPage: 1,
      type: 'loop',
      pagination: false,
      keyboard: false,
      slideFocus: false
    }).mount();

    // To prevent animation issues, let's make every slide visible before a transition happens. Splide will then automatically remove the `.is-visible` class from non-visible slides once the transition is finished.
    splide.on('move', function() {
      var slides = document.querySelectorAll('.splide .splide__slide');

      slides.forEach(function(slide) {
        slide.classList.add('is-visible');
      });
    });

    // Go to the previous slide and stop autoplay when the Previous button is activated
    previousButton.addEventListener('click', function(e) {
      splide.go('<');
    });

    // Go to the next slide and stop autoplay when the Next button is activated
    nextButton.addEventListener('click', function(e) {
      splide.go('>');
    });
  });
}

function wheelSlider () {
  let wheelSlider;

  document.addEventListener('DOMContentLoaded', function() {
    wheelSlider = new Splide('.wheel-slider', {
      autoplay: false,
      interval: 7000,
      pauseOnHover: false,
      arrows: false,
      perPage: 1,
      type: 'fade',
      pagination: false,
      keyboard: false,
      slideFocus: false,
    }).mount( { URLHash } );
  });

  let elems = document.querySelectorAll('.segment');

  document.querySelector('#TopSegmentGroup').addEventListener('click', function(e) {
    wheelSlider.go( 0 );
    [].forEach.call(elems, function(el) {
      el.classList.remove('segment-active');
    });
    document.getElementById('TopSegmentGroup').classList.add('segment-active');
  });

  document.querySelector('#RightSegmentGroup').addEventListener('click', function(e) {
    wheelSlider.go( 1 );
    [].forEach.call(elems, function(el) {
      el.classList.remove('segment-active');
    });
    document.getElementById('RightSegmentGroup').classList.add('segment-active');
  });

  document.querySelector('#BottomSegmentGroup').addEventListener('click', function(e) {
    wheelSlider.go( 2 );
    [].forEach.call(elems, function(el) {
      el.classList.remove('segment-active');
    });
    document.getElementById('BottomSegmentGroup').classList.add('segment-active');
  });

  document.querySelector('#LeftSegmentGroup').addEventListener('click', function(e) {
    wheelSlider.go( 3 );
    [].forEach.call(elems, function(el) {
      el.classList.remove('segment-active');
    });
    document.getElementById('LeftSegmentGroup').classList.add('segment-active');
  });
}

function teaserSlider() {
  let teaserSlider;
  let teaserPreviousButton, teaserNextButton;

  document.addEventListener('DOMContentLoaded', function() {
    teaserPreviousButton = document.querySelector('.teaser-slider-controls .previous-button');
    teaserNextButton = document.querySelector('.teaser-slider-controls .next-button');

    teaserSlider = new Splide('.teaser-slider', {
      gap: '1.25rem',
      arrows: false,
      rewind: true,
      perPage: 3,
      gap: '1.25em',
      type: 'slide',
      pagination: false,
      keyboard: false,  // Splide listens to key events at the document level and moves ALL carousels when arrow keys are used. Since keyboard and screen reader users use these keys to operate the tabs, this creates a very unusual experience.
      slideFocus: false,  // removes tabindex="0" from each slide wrapper, since we only want our links inside each slide to receive focus.
      breakpoints: {
        1: {
          perPage: 1,
          type: 'loop',
          padding: {
            right: '3.75rem',
            left: '3.75rem',
          }
        },
        768: {
          perPage: 1,
          padding: {
            right: '3.75rem',
            left: '3.75rem',
          }
        },
        992: {
          perPage: 2,
        },
        1200: {
          perPage: 3,
        }
      }
    } ).mount();

    // Go to the previous slide when the Previous button is activated
    teaserPreviousButton.addEventListener('click', function(e) {
      teaserSlider.go('<');
    });

    // Go to the next slide when the Next button is activated
    teaserNextButton.addEventListener('click', function(e) {
      teaserSlider.go('>');
    });
  });
}


// Init function

heroSlider();
wheelSlider();
teaserSlider();


///
// poster frame click event
///

$(document).on('click', '.js-videoPoster', function (ev) {
  ev.preventDefault();
  var $poster = $(this);
  var $wrapper = $poster.closest('.js-videoWrapper');
  videoPlay($wrapper);
});

// play the targeted video (and hide the poster frame)
function videoPlay($wrapper) {
  var $iframe = $wrapper.find('.js-videoIframe');
  var src = $iframe.data('src');
  // hide poster
  $wrapper.addClass('videoWrapperActive');
  // add iframe src in, starting the video
  $iframe.attr('src', src);
}

// stop the targeted/all videos (and re-instate the poster frames)
function videoStop($wrapper) {
  // if we're stopping all videos on page
  if (!$wrapper) {
    var $wrapper = $('.js-videoWrapper');
    var $iframe = $('.js-videoIframe');
    // if we're stopping a particular video
  }
  else {
    var $iframe = $wrapper.find('.js-videoIframe');
  }
  // reveal poster
  $wrapper.removeClass('videoWrapperActive');
  // remove youtube link, stopping the video from playing in the background
  $iframe.attr('src', '');
}


function deeplink() {
  let url = location.href.replace(/\/$/, '');

  if (location.hash) {
    const hash = url.split('#');
    const currentTab = document.querySelector('#myTab a[href="#' + hash[1] + '"]');
    const curTab = new bootstrap.Tab(currentTab);
    curTab.show();
    url = location.href.replace(/\/#/, '#');
    history.replaceState(null, null, url);
    setTimeout(() => {
      window.scrollTop = 0;
    }, 400);
  }

// change url based on selected tab
  const selectableTabList = [].slice.call(document.querySelectorAll('a[data-bs-toggle="tab"]'));
  selectableTabList.forEach((selectableTab) => {
    const selTab = new bootstrap.Tab(selectableTab);
    selectableTab.addEventListener('click', function () {
      var newUrl;
      const hash = selectableTab.getAttribute('href');
      if (hash == '#home-tab') {
        newUrl = url.split('#')[0];
      } else {
        newUrl = url.split('#')[0] + hash;
      }
      history.replaceState(null, null, newUrl);
    });
  });
}


// deeplink();
